import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { AuthenticationService, FormUtilsService, UserStatus } from '../../../../@hop/services';
import { CachingService } from '../../../../@hop/services/caching.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ValueAccessorDirective } from '../../../../@hop/_helpers/value-accessor.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';
import { MatFormErrorsModule } from '../../../../@hop/components/mat-form-errors/mat-form-errors.module';

import { LoginPhoneComponent } from './login/login-phone/login-phone.component';
import { RegisterPhoneComponent } from './register/register-phone/register-phone.component';
import { LoginPhoneCodeComponent } from './login/login-phone-code/login-phone-code.component';
import { PhoneInputComponent } from 'hop-calendar';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslatePipe,
    MatFormErrorsModule,
    RegisterPhoneComponent,
    LoginPhoneComponent,
    LoginPhoneCodeComponent,
    PhoneInputComponent
  ],
  selector: 'hop-fast-sign-in-phone',
  template: `
    @if (!phoneStatus || phoneStatus === 'unavailable') {
      <div [formGroup]="phoneForm" class="flex flex-col space-y-1">
        <mat-form-field appearance="fill">
          <!--<mat-label>{{ '_label.phone' | translate }}</mat-label>-->
          <phone-input
            inputPlaceholder="{{ '_contacts._label.phone' | translate }}"
            [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            name="phone"
            formControlName="phone"
            (blur)="checkPhone()"
            (keydown.enter)="checkPhone()"
            #phoneStatusControl
          ></phone-input>
          <!--<input
          (blur)="checkPhone()"
          (keydown.enter)="checkPhone()"
          [placeholder]="'_placeholder.phone' | translate"
          formControlName="phone"
          matInput
          #phoneStatusControl
          />-->
          <mat-error>
            <hop-mat-form-error control="phone"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
        <p data-testid="hop-fast-sign-in-global-button-forward" class="self-end cursor-pointer"><mat-icon>arrow_forward</mat-icon></p>
      </div>
    }
    @if (phoneStatus === 'available') {
      <div [formGroup]="registerForm" class="flex flex-col space-y-4">
        <hop-register-phone
          #registerPhoneRef
          [phone]="phoneForm.controls.phone.value"
          (phoneClicked)="resetPhoneStatus()"
          [isProfessional]="isProfessional"
        ></hop-register-phone>
      </div>
    }
    @if (loginMethod === 'password') {
      <div [formGroup]="loginForm" class="flex flex-col space-y-4">
        <hop-login-phone
          #loginPhoneRef
          [phone]="phoneForm.controls.phone.value"
          [navigateToDashboard]="false"
          (phoneClicked)="resetPhoneStatus()"
          [showUseCode]="true"
          (useCodeEvent)="useCode()"
        ></hop-login-phone>
      </div>
    }
    @if (loginMethod === 'code') {
      <div [formGroup]="loginForm" class="flex flex-col space-y-4">
        <hop-login-phone-code
          #loginPhoneCodeRef
          [phone]="phoneForm.controls.phone.value"
          [navigateToDashboard]="false"
          (phoneClicked)="resetPhoneStatus()"
        ></hop-login-phone-code>
      </div>
    }
  `,
  //styleUrls: ['./user-fast-login.component.scss'],
  hostDirectives: [ValueAccessorDirective]
})
export class FastSignInPhoneComponent implements OnInit {
  public phoneStatus: 'available' | 'unavailable' | 'exists' | 'exists_' | undefined;
  @ViewChildren(LoginPhoneComponent) loginPhoneRef: QueryList<LoginPhoneComponent>;
  @ViewChildren(LoginPhoneCodeComponent) loginPhoneCodeRef: QueryList<LoginPhoneCodeComponent>;
  @ViewChildren(RegisterPhoneComponent) registerPhoneRef: QueryList<RegisterPhoneComponent>;
  @Input() readOnly = false;
  @Input() isProfessional = false;
  protected loginMethod: 'password' | 'code' = null;
  protected isPasswordAvailable = false;
  preferredCountries: Array<string>;
  constructor(
    private formUtilsService: FormUtilsService,
    private authService: AuthenticationService,
    private cachingService: CachingService
  ) {}
  @ViewChild('phoneStatusControl') phoneStatusControl?: ElementRef;
  phoneForm: any;
  registerForm: any;
  loginForm: any;
  loginPhoneCodeForm: any;

  ngOnInit() {
    this.preferredCountries = this.environment.appConfig.preferredPhoneCountryCodes;
    this.phoneForm = this.formUtilsService.createFormFromObject({ phone: '' });
    this.registerForm = this.formUtilsService.createFormFromObject({ phone: '', firstName: '', lastName: '' });
    this.loginForm = this.formUtilsService.createFormFromObject({ phone: '', password: '' });
    this.loginPhoneCodeForm = this.formUtilsService.createFormFromObject({ phone: '', phoneCode: '' });
    this.phoneForm.controls.phone.setValidators([Validators.required]);
    this.registerForm.controls.phone.setValidators([Validators.required]);
    this.registerForm.controls.firstName.setValidators([Validators.required, Validators.minLength(3)]);
    this.registerForm.controls.lastName.setValidators([Validators.required, Validators.minLength(3)]);
    /*this.phoneForm.valueChanges.subscribe((data) => {
      const phoneControl = this.phoneForm.get('phone');
      console.log(data, phoneControl);
    });*/
  }

  async checkPhone() {
    const phoneControl = this.phoneForm.get('phone');
    if (phoneControl.valid) {
      this.cachingService
        .remember('user-status-' + phoneControl.value, this.authService.phoneStatus(phoneControl.value), false, 0.3)
        .pipe(
          catchError((e) => {
            if (e?.error?.errors) {
              this.formUtilsService.setFormErrors(e.error, this.phoneForm);
            }
            return throwError(e);
          })
        )
        .subscribe((data: UserStatus) => {
          if (data.success) {
            this.phoneStatus = data.status;
            if (data.status === 'unavailable') {
              this.phoneForm.controls.phone.setErrors({ unavailable: true });
            }
            if (data.status === 'available') {
              this.registerForm.controls.phone.setValue(this.phoneForm.controls.phone.value);
              setTimeout(() => {
                this.registerPhoneRef.get(0).firstNameRef.nativeElement.focus();
              }, 100);
            }
            if (data.status === 'exists') {
              this.usePassword();
            }
            if (data.status === 'exists_') {
              this.useCode();
            }
          } else {
            this.phoneForm.controls.phone.setErrors({ 'contact-support': true });
          }
        });
    }
  }

  resetPhoneStatus() {
    this.phoneStatus = undefined;
    this.loginMethod = null;
    this.isPasswordAvailable = false;
    setTimeout(() => {
      this.phoneStatusControl?.nativeElement?.focus();
    }, 50);
  }

  usePassword() {
    this.loginMethod = 'password';
    this.isPasswordAvailable = true;
    setTimeout(() => {
      this.loginForm.controls.phone.setValue(this.phoneForm.controls.phone.value);
      setTimeout(() => {
        this.loginPhoneRef.get(0).passwordRef.nativeElement.focus();
      }, 100);
    }, 100);
  }

  useCode() {
    this.loginMethod = 'code';
    setTimeout(() => {
      this.loginPhoneCodeForm.controls.phone.setValue(this.phoneForm.controls.phone.value);
      setTimeout(() => {
        this.loginPhoneCodeRef.first.phoneCodeRef.sendPhoneCode();
        setTimeout(() => {
          const loginPhoneCodeField = this.loginPhoneCodeRef.get(0).phoneCodeRef;
          loginPhoneCodeField.onFocusIn(null);
        }, 5000);
      }, 1000);
    }, 100);
  }

  protected readonly environment = environment;
}
