import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { ConfigService } from '../../config/config.service';
import { debounceTime, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiNameSet, AppVersionSet, BrandSet, ColorSchemeSet, LanguageSet, LanguagesSet } from '../actions/app.action';
import { Language, LanguagesEnum } from 'src/@hop/models/language-model';
import { TranslateService } from '@ngx-translate/core';
import * as cookie from 'js-cookie';
import { Meta } from '@angular/platform-browser';
import { AppConfigService } from '../../services/app-config.service';
import { environment } from '../../../environments/environment';

export class AppHopStateModel {
  colorScheme: ColorSchemeName;
  language: Language;
  availableLanguages: Language[];
  apiName: string;
  appVersion: string;
  brand: string;
}

@State<AppHopStateModel>({
  name: 'appState',
  defaults: {
    colorScheme: ColorSchemeName.light,
    language: {
      name: 'English',
      nameEnglish: 'English',
      code: 'en',
      flag: '🇬🇧',
      isManuallySet: false
    },
    availableLanguages: [
      {
        name: 'English',
        nameEnglish: 'English',
        code: 'en',
        flag: '🇬🇧'
      },
      {
        name: 'Deutsch',
        nameEnglish: 'German',
        code: 'de',
        flag: '🇩🇪',
        isDisabled: false
      },
      {
        name: 'Francais',
        nameEnglish: 'French',
        code: 'fr',
        flag: '🇫🇷',
        isDisabled: false
      },
      {
        name: 'Español',
        nameEnglish: 'Spanish',
        code: 'es',
        flag: '🇪🇸',
        isDisabled: false
      },
      {
        name: 'Română',
        nameEnglish: 'Romanian',
        code: 'ro',
        flag: '🇷🇴'
      },
      {
        name: 'Italiano',
        nameEnglish: 'Italian',
        code: 'it',
        flag: '🇮🇹'
      },
      {
        name: 'Nederlands',
        nameEnglish: 'Dutch',
        code: 'nl',
        flag: '🇧🇪'
      },
      {
        name: 'Polski',
        nameEnglish: 'Polish',
        code: 'pl',
        flag: '🇵🇱'
      }
    ],
    apiName: 'default',
    appVersion: '',
    brand: '_'
  }
})
@UntilDestroy()
@Injectable()
export class AppHopState implements NgxsAfterBootstrap {
  @Select(AppHopState.selectColorScheme) colorScheme$: Observable<ColorSchemeName>;
  @Select(AppHopState.selectLanguage) language$: Observable<any>;
  @Select(AppHopState.selectApiName) apiName$: Observable<string>;

  constructor(
    private store: Store,
    private configService: ConfigService,
    private translate: TranslateService,
    private meta: Meta,
    private appConfigService: AppConfigService
  ) {
    translate.setDefaultLang(environment.defaultLangCode);

    this.colorScheme$.subscribe((colorScheme) => {
      // console.log('colorScheme', colorScheme);
      this.configService.updateConfig({
        style: {
          colorScheme
        }
      });
    });

    this.language$.pipe(debounceTime(200)).subscribe((language) => {
      // console.log('pune traducere', language);
      // if (language?.name) {
      translate.use(language?.code);
      cookie.set('lang', language?.code);
      // }
    });

    this.apiName$.subscribe((appName) => {
      if (appName && appName !== 'default') {
        return cookie.set('apiName', appName);
      }
      cookie.remove('apiName');
    });
    const versionTag = this.meta.getTag('name=version');
    setTimeout(() => {
      if (versionTag?.content) {
        this.store.dispatch(new AppVersionSet(versionTag?.content));
      }
    }, 1000);
  }

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    const state = ctx.getState();
    const navigatorLanguage = navigator.language.replace(/-.+/, '') as LanguagesEnum;
    // console.log('test', LanguagesEnum);
    let foundIpLanguage = null;
    if (environment.appConfig.ipLangCode === environment.defaultLangCode) {
      foundIpLanguage = state.availableLanguages.find((l) => l.code === environment.appConfig.ipLangCode);
    }
    const foundLanguage = foundIpLanguage || state.availableLanguages.find((l) => l.code === navigatorLanguage);
    if (foundLanguage) {
      this.store.dispatch(new LanguageSet(foundLanguage));
    } else {
      const foundLanguage = state.availableLanguages.find((l) => l.code === environment.defaultLangCode);
      this.store.dispatch(new LanguageSet(foundLanguage));
    }
    if (['cosmoslevel.com', 'proo.ro', 'adu.koschedule.com', 'proo.ro'].includes(environment.name)) {
      this.store.dispatch(new BrandSet(environment.name));
    }
  }

  @Selector()
  static selectLanguage(state: AppHopStateModel) {
    return state?.language;
  }

  @Selector()
  static selectBrand(state: AppHopStateModel) {
    return state?.brand;
  }

  @Selector()
  static selectAvailableLanguages(state: AppHopStateModel) {
    return [...state?.availableLanguages];
  }

  @Selector()
  static selectColorScheme(state: AppHopStateModel) {
    return state?.colorScheme;
  }

  @Selector()
  static selectApiName(state: AppHopStateModel) {
    return state?.apiName;
  }

  @Selector()
  static selectAppVersion(state: AppHopStateModel) {
    return state?.appVersion || null;
  }

  @Action(LanguageSet)
  setLanguage(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    if (window.history.replaceState) {
      //prevents browser from storing history with each change:
      window.history.replaceState({}, null, window.location.href.replace(/lang=../, 'lang=' + appState.language.code));
    }
    //console.log('make a choice');
    if (!appState.language.isManuallySet && state.language.isManuallySet) {
      //console.log('return');
      return;
    }
    ctx.setState({
      ...state,
      language: appState.language
    });
  }

  @Action(BrandSet)
  setBrand(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      brand: appState.brand
    });
  }

  @Action(LanguagesSet)
  setLanguages(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      availableLanguages: [...appState.availableLanguages],
      language: state.language.isManuallySet ? state.language : { ...appState.availableLanguages[0] }
    });
  }

  @Action(ColorSchemeSet)
  setColorScheme(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      colorScheme: appState.colorScheme
    });
  }

  @Action(ApiNameSet)
  setApiName(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiName: appState.apiName
    });
  }

  @Action(AppVersionSet)
  setAppVersion(ctx: StateContext<AppHopStateModel>, appState: AppHopStateModel) {
    if (appState.appVersion) {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        appVersion: appState.appVersion
      });
    }
  }
}
