import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullReviewComponentComponent } from './full-review-component.component';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingViewModule } from 'hop-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WriteReviewComponentModule } from '../write-review-component/write-review-component.module';
import { EditReviewComponentModule } from '../edit-review-component/edit-review-component.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppRouterLinkDirective } from '../../../../../@hop/components/router-link/router-link.directive';
import { ReadMoreDirective } from '../../../../../@hop/components/directives/read-more/read-more.directive';
import { WriteCommentComponentModule } from '../../comments/write-comment/write-comment.module';
import { AllCommentsModule } from '../../comments/all-comments/all-comments.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FullReviewComponentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    StarsRatingViewModule,
    WriteReviewComponentModule,
    FormsModule,
    EditReviewComponentModule,
    RouterModule,
    TranslateModule,
    ReadMoreDirective,
    AppRouterLinkDirective,
    WriteCommentComponentModule,
    AllCommentsModule,
    MatIconModule
  ],
  exports: [FullReviewComponentComponent]
})
export class FullReviewComponentModule {}
