import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from 'src/@hop/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormErrorsModule } from '../mat-form-errors/mat-form-errors.module';
import { UserEmailCodeComponent } from '../user-email-code/user-email-code.component';
import { ProfileService } from 'src/@hop/services/profile.service';
@UntilDestroy()
@Component({
  selector: 'delete-phone',
  template: `
    <p>
      {{ '_edit_phone_email.info_text_phone_delete' | translate }} <span class="text-green">{{ email }}</span>
    </p>

    <div [formGroup]="form" class="flex flex-col w-full">
      <mat-form-field class="w-full">
        <mat-label>{{ '_account._register.email-code' | translate }}</mat-label>
        <hop-user-email-code #userEmailCodeRef [emailToSend]="email" formControlName="emailCode"></hop-user-email-code>
        <mat-error data-testid="error-email-code">
          <hop-mat-form-error control="emailCode"></hop-mat-form-error>
        </mat-error>
      </mat-form-field>

      <button data-testid="button-phone-delete-submit" class="mt-4 self-end" mat-raised-button (click)="submit(form)" color="primary">
        {{ '_edit_phone_email.removePhone' | translate }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    TranslatePipe,
    MatFormErrorsModule,
    UserEmailCodeComponent,
    ReactiveFormsModule
  ]
})
export class DeletePhoneComponent implements OnInit {
  user: UserModel;
  form: UntypedFormGroup;
  email: string;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  @Output() onDeletePhone = new EventEmitter();
  constructor(
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private formUtilsService: FormUtilsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.email = user?.email;
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      emailCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  submit(form) {
    if (!form.valid) {
      return;
    } else {
      this.profileService.removePhone(this.form?.value).subscribe({
        next: (result) => {
          this.onDeletePhone.next(form);
          this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.phone_removed'), {
            timeOut: 3000,
            progressBar: true
          });
        },
        error: (error) => {
          if (error.error) {
            this.toastr.error(this.translateService.instant('_general.check_form_for_errors'), this.translateService.instant('_general.error'), {
              timeOut: 3000,
              progressBar: true
            });
            this.formUtilsService.setFormErrors(error.error, this.form);
          }
        }
      });
    }
  }
}
