import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetPasswordComponent } from './set-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '@ngx-translate/core';
import { MatFormErrorsModule } from 'src/@hop/components/mat-form-errors/mat-form-errors.module';
import { PasswordStrengthMeterModule } from 'src/@hop/components/password-strength-meter/password-strength-meter.module';

@NgModule({
  declarations: [SetPasswordComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    TranslatePipe,
    MatFormErrorsModule,
    PasswordStrengthMeterModule
  ],
  exports: [SetPasswordComponent]
})
export class SetPasswordModule {}
