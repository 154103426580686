import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { fadeInUp400ms } from '../../../../../../@hop/animations/fade-in-up.animation';
import { Select, Store } from '@ngxs/store';
import { UserLogin } from 'src/@hop/state/actions/user.action';
import { UserState } from 'src/@hop/state/states/user-state.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../../../@hop/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as cookie from 'js-cookie';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslatePipe } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'hop-login-email',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    TranslatePipe,
    RouterLink
  ],
  template: `
    <div (keyup.enter)="send()" [formGroup]="form" class="flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1">
          <mat-label>{{ '_label.email' | translate }}</mat-label>
          <input formControlName="email" matInput required (click)="clickEmail()" #emailRef />
          @if (form.get('email').hasError('required')) {
            <mat-error>{{ '_account._login.we-need-an-email-address' | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>{{ '_label.password' | translate }}</mat-label>
          <input [type]="inputType" formControlName="password" matInput #passwordRef required />
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            @if (visible) {
              <mat-icon svgIcon="mat:visibility"></mat-icon>
            }
            @if (!visible) {
              <mat-icon svgIcon="mat:visibility_off"></mat-icon>
            }
          </button>
          <!--<mat-hint>{{ '_account._login.passwordHint' | translate }}</mat-hint>-->
          @if (form.get('password').hasError('required')) {
            <mat-error>{{ '_account._login.we-need-a-password' | translate }}</mat-error>
          }
        </mat-form-field>
        <!--show use code link if showUseCode and when clicked emit useCodeEvent-->
        @if (showUseCode) {
          <p class="flash-once w-full text-center underline cursor-pointer" (click)="useCodeEvent.emit(true)">
            {{ '_account._login.receive-login-code-by-email' | translate }}
          </p>
        }
      </div>

      <div class="flex grow items-center" [class.justify-between]="passwordIsEnough" [class.justify-center]="!passwordIsEnough">
        @if (passwordIsEnough) {
          <mat-checkbox formControlName="rememberMe" class="caption" color="primary">{{ '_account._login.remember-me' | translate }}</mat-checkbox>
        }
        <a
          (click)="forgotPassword()"
          [routerLink]="['/forgot-password']"
          [queryParams]="{ email: form.get('email').value }"
          [target]="!navigateToDashboard ? '_blank' : '_self'"
          class="caption"
          >{{ '_account._login.forgot-password' | translate }}</a
        >
      </div>

      @if (passwordIsEnough) {
        <button (click)="send()" color="primary" mat-raised-button type="button" data-testid="button-sign-in">
          {{ '_account._login.sign-in' | translate }}
        </button>
      }
    </div>
  `,
  styles: [
    `
      @keyframes flash {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .flash-once {
        animation: flash 1s ease-in-out 3s;
      }
    `
  ],
  //styleUrls: ['./login-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms]
})
export class LoginEmailComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Input() navigateToDashboard: boolean = true;
  @Input() email = '';
  @Output() emailClicked = new EventEmitter();
  @ViewChild('passwordRef') public passwordRef: ElementRef;
  @Input() showUseCode = false;
  @Output() useCodeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  password = '';
  inputType = 'password';
  visible = false;
  get passwordIsEnough() {
    return this.form.get('password').value?.length > 5;
  }

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((e: NavigationStart) => {
      const navigation = this.router.getCurrentNavigation();
      this.email = navigation?.extras?.state ? navigation.extras.state['email'] : this.email;
      this.password = navigation?.extras?.state ? navigation.extras.state['password'] : '';
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [this.email, Validators.required],
      password: [this.password, Validators.required],
      rememberMe: [false]
    });
  }

  ngAfterViewInit(): void {}

  send() {
    if (this.form.invalid) {
      return;
    }
    this.store
      .dispatch(new UserLogin(this.f['email'].value, this.f['password'].value, this.f['rememberMe'].value))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (this.navigateToDashboard) {
            this.router.navigate(['/dashboard']);
          }
          // this.store.dispatch(new UserGet()).pipe(untilDestroyed(this)).subscribe();
        },
        error: (error) => {}
      });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
  get f() {
    return this.form.controls;
  }

  forgotPassword() {
    cookie.set('auth-close', 'true', {
      expires: 0.01
    });
  }

  clickEmail() {
    this.emailClicked.emit(true);
  }
}
