import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WriteReviewComponentComponent } from './write-review-component.component';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingModule } from 'hop-calendar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormErrorsModule } from 'src/@hop/components/mat-form-errors/mat-form-errors.module';
import { TranslatePipe } from '@ngx-translate/core';
import { FastSignInGlobalComponent } from '../../auth/fast-sign-in-global.component';
import { SafeHtmlPipe } from '../../../../../@hop/pipes/safeHtml.pipe';

@NgModule({
  declarations: [WriteReviewComponentComponent],
  exports: [WriteReviewComponentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    StarsRatingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatFormErrorsModule,
    FastSignInGlobalComponent,
    TranslatePipe,
    SafeHtmlPipe
  ]
})
export class WriteReviewComponentModule {}
