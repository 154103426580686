import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { UserEdit, UserGet, UserState } from 'src/@hop/state';
import { FormUtilsService, UserService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NamesModule } from '../names/names.module';
import { MatIconModule } from '@angular/material/icon';

@UntilDestroy()
@Component({
  selector: 'hop-dialog-profile',
  templateUrl: './dialog-profile.component.html',
  styleUrls: ['./dialog-profile.component.scss'],
  standalone: true,
  imports: [NamesModule, MatIconModule, TranslatePipe, MatDialogModule]
})
export class DialogProfileComponent implements OnInit {
  user: UserModel;

  @Select(UserState.selectUser)
  user$: Observable<UserModel>;

  constructor(
    public dialogRef: MatDialogRef<DialogProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private store: Store,
    private snackbar: MatSnackBar,
    private formUtilsService: FormUtilsService,
    private toastr: ToastrService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  submit(form) {
    if (!form.valid) {
      return;
    } else {
      this.userService
        .saveUser(form.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (result) => {
            this.toastr.success(this.translateService.instant('_general.changes_saved'), '', { timeOut: 3000, progressBar: true });
            // this.toastr.info(this.translateService.instant('_general.changes_saved'), '', { timeOut: 3000, progressBar: true });
            this.dialogRef.close();
            this.store.dispatch(new UserEdit(result));
          },
          error: (error) => {
            if (error.error.errors) {
              this.formUtilsService.setFormErrors(error.error, form);
            }
          }
        });
    }
  }
}
