import { AfterViewInit, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LayoutService } from 'src/@hop/services';
import { ChatWootService } from 'src/app/services';

@UntilDestroy()
@Component({
  selector: 'button-help-chatwoot',
  template: `
    <a matTooltip="{{ '_general.ask_for_help' | translate }}" matTooltipPosition="above" (click)="openChatWoot()" mat-icon-button type="button">
      <mat-icon>live_help</mat-icon>
    </a>
  `,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslatePipe, MatTooltipModule]
})
export class ButtonHelpChatwoot implements AfterViewInit {
  gtMd$ = this.layoutService.gtMd$;

  constructor(
    private layoutService: LayoutService,
    private chatwootService: ChatWootService
  ) {}
  ngAfterViewInit(): void {
    this.gtMd$.pipe(untilDestroyed(this)).subscribe((gtMd) => {
      if (gtMd) {
        this.chatwootService.toggleBubbleVisibility('show');
      } else {
        this.chatwootService.toggleBubbleVisibility('hide');
      }
    });
  }

  openChatWoot() {
    this.chatwootService.openChatWoot();
  }
}
