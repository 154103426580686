import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { OrderModel } from '../../models/order.model';
import { ProviderModel } from '../../models/provider.model';
import { firstValueFrom } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { MatMenuModule } from '@angular/material/menu';
import { getDiff } from 'recursive-diff';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BankTransferDialogComponent } from './bank-transfer-dialog.component';
import { CashPaymentDialogComponent } from './cash-payment-dialog.component';

@Component({
  selector: 'hop-order-payment-button',
  template: `
    @if (
      order && order.paymentStatus === 'unpaid' && order.status !== 'completed' && order.status !== 'pendingPayment' && order.status !== 'cancelled'
    ) {
      <div>
        @if (providers?.length && order.subtotal) {
          <div class="flex flex-col items-center space-y-2">
            <h3>
              {{ '_orders.choose-payment-method' | translate }}
            </h3>
            @for (provider of providers; track provider; let isFirst = $first) {
              <div>
                @if (isFirst) {
                  <button
                    [attr.data-testid]="'card-order-payment-button-' + provider.provider"
                    mat-raised-button
                    color="primary"
                    (click)="pay(provider)"
                  >
                    <span class="flex flex-row items-center">
                      <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: provider.provider }"></ng-container>
                      <span>&nbsp;{{ '_orders.pay-with.' + provider.provider | translate }}</span>
                    </span>
                  </button>
                }
                @if (!isFirst) {
                  <button [attr.data-testid]="'card-order-payment-button-' + provider.provider" mat-button color="primary" (click)="pay(provider)">
                    <span class="flex flex-row items-center">
                      <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: provider.provider }"></ng-container>
                      <span>&nbsp;{{ '_orders.pay-with.' + provider.provider | translate }}</span>
                    </span>
                  </button>
                }
              </div>
            }
          </div>
        }
        <!--if no payment providers then show a complete order button-->
        @if (providers?.length === 0 || !order.subtotal) {
          <a
            data-testid="hop-order-payment-button-complete-with-no-payment-method"
            mat-raised-button
            color="primary"
            (click)="clientSendOrderNoPaymentProviders()"
            class="w-full sm:w-64"
          >
            {{ '_orders.send-order' | translate }}
          </a>
        }
      </div>
    }

    <ng-template #iconTemplate let-provider>
      @if (provider === 'stripeTest' || provider === 'stripeLive') {
        <mat-icon>credit_card</mat-icon>
      }
      @if (provider === 'bankPayment') {
        <mat-icon>account_balance</mat-icon>
      }
      @if (provider === 'cashPayment') {
        <mat-icon svgIcon="logo:cash"></mat-icon>
      }
    </ng-template>
  `,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslatePipe, JsonPipe, MatMenuModule, RouterLink, NgTemplateOutlet, MatDialogModule]
})
export class OrderPaymentButtonComponent implements OnInit, OnChanges {
  providers: ProviderModel[] = null;
  @Input() order: OrderModel;
  @Input() copy: string;

  constructor(
    private router: Router,
    @Inject('environment') public environment,
    private ordersService: OrdersService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if the order changes, we need to get the payment providers again use getDiff
    const diff = getDiff(changes.order.previousValue, changes.order.currentValue);
    if (diff.length) {
      this.getPaymentProviders();
    }
  }

  ngOnInit(): void {
    //this.getPaymentProviders();
  }

  async getPaymentProviders() {
    this.providers = await firstValueFrom(this.ordersService.getPaymentProviders(this.order.uuid));
  }

  pay(provider) {
    if (provider.provider === 'stripeTest') {
      this.ordersService.getStripeTestPaymentLink(this.order.uuid).subscribe((data) => {
        window.open(data.url, '_self');
      });
    }
    if (provider.provider === 'stripeLive') {
      this.ordersService.getStripeLivePaymentLink(this.order.uuid).subscribe((data) => {
        window.open(data.url, '_self');
      });
    }
    if (provider?.provider === 'bankPayment') {
      this.payWithBankTransfer(provider);
    } else if (provider?.provider === 'cashPayment') {
      this.payWithCash(provider);
    }
  }
  payWithBankTransfer(provider) {
    this.dialog.open(BankTransferDialogComponent, {
      data: {
        provider: provider,
        order: this.order
      },
      panelClass: 'dialog-open-menu-action'
    });
  }

  payWithCash(provider) {
    this.dialog.open(CashPaymentDialogComponent, {
      data: {
        provider: provider,
        order: this.order
      },
      panelClass: 'dialog-open-menu-action'
    });
  }

  clientSendOrderNoPaymentProviders() {
    this.ordersService.clientSendOrderNoPaymentProviders(this.order.uuid).subscribe(
      (order) => {
        this.toastr.success(this.translateService.instant('_orders.order-completed'));
        this.router.navigate([order._uriCode]);
      },
      (err) => {
        this.toastr.error(this.translateService.instant('_orders.error-completing-order'));
      }
    );
  }
}
